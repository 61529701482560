body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: white;
  height: 100vh;
}

.cursor {
  pointer-events: none;
  position: fixed;
  display: block;
  border-radius: 0;
  mix-blend-mode: difference;
  top: 0;
  left: 0;
  z-index: 9999999999999999;
}

.circle {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 18px;
  background-color: #ff9933; /* Saffron color */
}
