.bordercolor{
border-color:rgb(100 255 218 );
border-radius: .5rem;
}
/* Inside your CSS file or as inline styles */

body {
    box-sizing: border-box;
    padding: 0;
    margin:0;
    background-color: white;
    height: 100vh;
  }
  
  .cursor {
    pointer-events: none;
    position: fixed;
    display: block;
    border-radius: 0;
    mix-blend-mode: difference;
    top: 0;
    left: 0;
    z-index: 9999999999999999;  
  }
  
  .circle {
      position: absolute;
      display: block;
      width: 26px;
      height: 26px;
      border-radius: 20px;
      background-color: #fff;
  }
  .backgroundStyle {
    background-size: contain;
    opacity: 0.6;
    background-position: top right;
    background-repeat: no-repeat;
  }
  
  @media (max-width: 480px) {
    .backgroundStyle {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  